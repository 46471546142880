<template>
<div>
  <div class="titleFont">补录信息</div>
  <base-form
      :componentList="SupplementaryInformationConfig"
      :formAttrs="{
        model: formData,
        labelWidth: '140px',
      }"
      :showBtns="false"
      class="formData"
      ref="shareholderMeeting"
    />
</div>
</template>

<script>
import { SupplementaryInformationConfig } from '../utils/config'
import BaseForm from '@/components/common/base-form/base-form.vue'
export default {
  components: { BaseForm },
  props: {},
  data () {
    return {
      formData: {

      }
    }
  },
  // 计算属性 类似于data概念
  computed: {
    SupplementaryInformationConfig () {
      return SupplementaryInformationConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {
    '$attrs.value': {
      handler (val) {
        this.$set(this.formData, 'shareholdersArrivingNum', val)
      },
      deep: true
    },
    'formData.shareholdersArrivingNum': {
      handler (val) {
        this.$emit('changeShareholdersArrivingNum', val)
      },
      deep: true
    }
  },
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.formData{
  /deep/ .el-form-item__label{
  font-size: 14px;
  color: #757380;
}
}
.titleFont{
  font-family: SourceHanSansSC-bold;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
</style>
